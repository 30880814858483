import { Component } from 'react'
import {
  ConfigurationDetail,
  Encodings,
  EncodingValidationRequest,
  EncodingValidationResponse,
  TmrTag,
} from 'stylewhere/api'
import {
  Box,
  Button,
  EncodingRightHeader,
  IdentifierBox,
  Page,
  ProductInfoBox,
  EncodingReading,
  FullLoadingLayer,
} from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { AppStore, OperationReadingProps, RemoteOperation, RfidReader, Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  askUserConfirmation,
  showToast,
  showToastError,
  getEncodingProductFields,
  isRfidAndSerial,
  enabledDisassociateItemTag,
  getAutomaticRestartAntenna,
  isModalError,
} from 'stylewhere/shared/utils'

interface State {
  encodingValidation: EncodingValidationRequest
  encodingValidationResponse?: EncodingValidationResponse
  antennaOn: boolean
  processing: boolean
  options: {
    value: string
    label: string
    active: boolean
  }[]
  starting: boolean
  originTemplatePath?: string
}

export default class EncodingVerify extends Component<OperationReadingProps<State>, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)
  timer: NodeJS.Timeout | null = null
  isModal = false

  state: State = {
    encodingValidation: {
      operationId: this.operation.id,
      identifiers: [],
    },
    options: [
      { value: 'associate', label: __(T.misc.associate), active: false },
      { value: 'verify', label: __(T.misc.verify), active: true },
    ],
    antennaOn: false,
    processing: false,
    starting: false,
  }

  componentDidMount() {
    this.isModal = isModalError(this.operation)
    this.setState({ starting: true, originTemplatePath: this.locationState.originTemplatePath }, this.startReader)
  }

  startReader = async () => {
    try {
      await RfidReader.initialize()
      RfidReader.setOnTagReadCallback(this.onTagRead)
      RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
      RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    } catch (error) {
      showToastError((error as any).message ?? __(T.error.rfid_reader_initialization), __(T.error.error), this.isModal)
    }
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true, starting: false })
  }

  onStopCallback = () => {
    this.setState({ antennaOn: false, starting: false })
  }

  componentWillUnmount = async () => {
    this.setState({ processing: false })
    await this.stopAntenna()
  }

  onTagRead = async (tag: TmrTag) => {
    const { encodingValidation } = this.state

    try {
      this.stopTimer()
      await EncodingExtensions.onTagRead(encodingValidation, tag, this.operation)
      this.setState({ encodingValidation }, this.onTagReadTimer)
    } catch (error) {
      console.error(error)
    }
  }

  onTagReadTimer = async () => {
    console.log('onTagReadTimer', this.state.encodingValidation)
    this.timer = setTimeout(async () => {
      this.setState({ processing: true }, this.verifyTag)
    }, this.operation.options.timeoutValidateTags ?? 700)
  }

  sendMixpanelEventData = async (event) => {
    const { encodingValidation } = this.state
    const mixpanelData = EncodingExtensions.getMixPanelData(this.operation, encodingValidation)
    AppStore.sendMixPanelEvent(event, mixpanelData)
  }

  verifyTag = async () => {
    const { encodingValidation } = this.state
    try {
      await this.stopAntenna()
      const encode = await EncodingExtensions.verify(encodingValidation!)
      await this.sendMixpanelEventData('Encoding: Verify Association')
      this.setState({
        encodingValidation: encode.encodingValidation,
        encodingValidationResponse: encode.encodingValidationResponse,
        processing: false,
      })
    } catch (error) {
      this.setState({ processing: false })
      showToastError(
        (error as any).message ?? 'Unknown error during encoding validation',
        __(T.error.error),
        this.isModal
      )
    }
  }

  stopTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  clearReads = async (addtags = false) => {
    const { encodingValidation } = this.state
    this.stopTimer()
    if (addtags && encodingValidation && encodingValidation.identifiers) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map(
          (idf) =>
            ({
              uid: idf.identifierType === 'NFC_TAG' && idf.code,
              epc: idf.identifierType === 'UHF_TAG' && idf.code,
              tid: idf.tid || '',
            } as any)
        )
    }
    this.setState(
      {
        encodingValidation: {
          operationId: this.operation.id,
          identifiers: [],
        },
        encodingValidationResponse: undefined,
      },
      this.restartAntenna
    )
  }

  restartAntenna = async () => {
    RfidReader.clear()
    if (!RfidReader.isReading()) {
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    }
  }

  renderIdentifierBox = (_identifier: ConfigurationDetail, index: any) => (
    <IdentifierBox
      widthPerc={(this.state.encodingValidation?.identifiers.length ?? 0) < 3 ? 100 : 50}
      identifier={_identifier}
      key={index}
      onInputSubmit={(barcode) => this.onTagRead({ barcode } as any)}
    />
  )

  canDeleteAssociation = () => {
    return AppStore.hasAnyCapabilities('Operation.Encoding.disassociate')
  }

  deleteItem = async () => {
    const { encodingValidationResponse } = this.state
    if (
      encodingValidationResponse?.item &&
      (await askUserConfirmation(__(T.misc.delete), __(T.confirm.confirm_delete_item_association)))
    ) {
      try {
        await Encodings.disassociate({ itemId: encodingValidationResponse.item.id, operationId: this.operation.id })
        await this.sendMixpanelEventData('Encoding: Item Disassociated')
        showToast({ status: 'success', description: __(T.messages.tag_deleted) })
        this.clearReads(true)
      } catch (error) {
        showToastError(error, __(T.error.error), this.isModal)
      }
    }
  }

  killTag = async () => {
    if (await askUserConfirmation(__(T.misc.kill_tag), __(T.confirm.confirm_kill_tag))) {
      showToast({ status: 'success', description: __(T.messages.tag_killed) })
      this.clearReads()
    }
  }

  onTabClick = (tab) => {
    if (tab === 'associate') {
      this.setState({ processing: true }, this._changeTab)
    }
  }

  _changeTab = async () => {
    const { originTemplatePath } = this.state
    RfidReader.clear()
    await this.stopAntenna()
    this.timer = setTimeout(() => {
      Router.navigate(`/encoding/${originTemplatePath ? `${originTemplatePath}/` : ''}:opCode` as any, {
        opCode: this.operation.code,
      })
    }, this.operation.options.antennaTurnWaitingInterval ?? 1000)
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    }
  }

  stopAntenna = async () => {
    if (RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.stop()
    }
  }

  render() {
    const { encodingValidation, encodingValidationResponse, antennaOn, processing, starting } = this.state
    const identifiers = encodingValidation?.identifiers || []
    const hasRfidAndSerial = isRfidAndSerial(this.operation)

    return (
      <Page
        headerRight={
          <EncodingRightHeader
            onTabClick={this.onTabClick}
            antennaOn={antennaOn}
            operation={this.operation}
            options={this.state.options}
          />
        }
        title={this.operation.description}
        enableEmulation
      >
        {identifiers.length > 0 && (
          <Page.Sidebar width={500}>
            <Box flex justify={'space-between'}>
              {!!encodingValidationResponse?.item?.product && (
                <ProductInfoBox product={encodingValidationResponse.item.product} fields={getEncodingProductFields()} />
              )}
              {!encodingValidationResponse?.item?.product && <Box flex />}
              {identifiers.length > 0 && (
                <Button variant="default" title={__(T.misc.clear)} onClick={() => this.clearReads(true)} />
              )}
            </Box>
          </Page.Sidebar>
        )}
        <Page.Content notBoxed>
          {identifiers.length > 0 && (
            <>
              <Box flex row justify={'space-between'} style={{ flexWrap: 'wrap', paddingBottom: 2 }}>
                {identifiers.map(this.renderIdentifierBox)}
              </Box>
              {!!encodingValidationResponse?.item?.id && enabledDisassociateItemTag() && this.canDeleteAssociation() && (
                <Box row>
                  {/*<Button flex variant="secondary" title={__(T.misc.kill_tag)} onClick={this.killTag} />
                  <Spacer />*/}
                  <Button flex title={__(T.misc.delete)} onClick={this.deleteItem} />
                </Box>
              )}
            </>
          )}
          {identifiers.length === 0 && (
            <>
              <EncodingReading
                starting={starting}
                startAntenna={this.startAntenna}
                antennaOn={antennaOn}
                message={
                  antennaOn ? __(T.messages.bring_an_item_to_antenna) : __(T.messages.connection_to_station_in_progress)
                }
                identifier={
                  hasRfidAndSerial
                    ? {
                        identifierType: 'SIMPLE_ITEM_IDENTIFIER',
                        _status: 'TO_BE_READ',
                      }
                    : undefined
                }
                onInputSubmit={hasRfidAndSerial ? (barcode) => this.onTagRead({ barcode } as any) : undefined}
              />
            </>
          )}
        </Page.Content>
        {processing && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
      </Page>
    )
  }
}
